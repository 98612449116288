<template>
  <div>
    <v-row>
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
        class="mt-0"
      >
        <v-btn
          class="mt-4"
          plain
          @click="openFilterDialog"
        >
          <v-icon
            large
          >
            mdi-filter-variant
          </v-icon>
          <span class="ml-5">Filteri</span>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-menu
          v-model="fromMenu"
          transition="slide-y-transition"
          nudge-top="450"
          offset-y
          min-width="auto"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromFormatted"
              :label="$t('base.from')"
              prepend-inner-icon="mdi-calendar-start"
              readonly
              v-bind="attrs"
              v-on="on"
              @blur="parseDmyDate(fromFormatted)"
            />
          </template>
          <datetime-picker
            v-model="from"
            :emit-default-time="true"
            :default-time="'00:00:00'"
            :max-date="maxDate"
            :reset-tabs="resetFromTabs"
            @close-picker="fromMenu = false"
            :on-double-click="closeDatetimePicker"
          >
            <v-col class="white text-right pb-2 px-2">
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="closeFromMenu"
              >
                {{ $t('base.ok') }}
              </v-btn>
            </v-col>
          </datetime-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-menu
          v-model="toMenu"
          transition="slide-y-transition"
          nudge-top="450"
          offset-y
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="toFormatted"
              :label="$t('base.to')"
              prepend-inner-icon="mdi-calendar-end"
              readonly
              v-bind="attrs"
              v-on="on"
              @blur="parseDmyDate(toFormatted)"
            />
          </template>
          <datetime-picker
            v-model="to"
            :emit-default-time="true"
            :default-time="defaultToTime"
            :min-date="minDate"
            :reset-tabs="resetToTabs"
            @close-picker="toMenu = false"
            :on-double-click="closeDatetimePicker"
          >
            <v-col class="white text-right pb-2 px-2">
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="closeToMenu"
              >
                {{ $t('base.ok') }}
              </v-btn>
            </v-col>
          </datetime-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-btn
          class="mt-4"
          plain
          @click="getFiltered"
        >
          <v-icon
            large
          >
            mdi-file-table
          </v-icon>
          <span class="ml-5">{{ $t('road-maintenance/patrol-service.show_order_list') }}</span>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
        v-if="canCreate"
      >
        <v-btn
          class="mt-4"
          plain
          @click="openCreateWorkOrderModal"
        >
          <v-icon
            large
          >
            mdi-plus
          </v-icon>
          <span class="ml-5">{{ $t('road-maintenance/excavation-service.add-work-order') }}</span>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
      >
        <v-btn
          class="mt-4"
          plain
          @click="exportData"
        >
          <v-icon
            large
          >
            mdi-application-export
          </v-icon>
          <span class="ml-5">{{ $t('road-maintenance/excavation-service.export') }}</span>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        md="auto" lg="auto" xl="auto"
        class="mt-n-0"
        v-show="historySwitchVisible && !active">
        <v-switch
          v-model="history"
          :label="$t('road-maintenance/patrol-service.show_history').toUpperCase()"
          color="green"
          value="true"
          hide-details
        ></v-switch>
      </v-col>
    </v-row>
    <v-dialog
      v-model="FilterDialog"
      max-width="700px"
      persistent
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="consentNumber"
                  autofocus
                  label="Broj suglasnosti"
                  clearable
                />
                <v-text-field
                  v-model="address"
                  label="Adresa"
                  clearable
                />
                <v-text-field
                  v-model="year"
                  label="Godina"
                  clearable
                />
                <v-combobox
                  v-model="selectedCityDistricts"
                  :items="cityDistricts"
                  item-text="name"
                  multiple
                  label="Gradske četvrti"
                  variant="underlined"
                />
                <v-combobox
                  v-model="dateKey"
                  label="Datum za filtriranje"
                  :items="dateKeys"
                  item-text="label"
                  variant="underlined"
                />
                <h2>{{ $t('road-maintenance/excavation-service.statuses') }}</h2>
                <v-switch
                  v-model="selectedExcavationOrderTypes"
                  v-for="excavationOrderType in excavationOrderTypes"
                  :key="excavationOrderType.value"
                  color="primary"
                  :value="excavationOrderType.value"
                  hide-details
                >
                  <template v-slot:label>
                    <div :class="excavationOrderType.icon + '_icon'" class="circle"/>
                    <div style="margin-left: 10px;">{{ $t(excavationOrderType.text) }}</div>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
            <v-row class="ma-0 flex flex-row-reverse mt-4">
              <v-btn
                color="primary"
                primary
                class="px-7 ml-5 mt-6"
                @click="filterStatus"
              >
                {{ $t('base.ok') }}
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="warningDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title>Upozorenje</v-card-title>
        <v-card-text>
          {{ warningMessage }}
        </v-card-text>
        <v-card-actions class="justify-end mt-4" style="background-color: #eeeeee;">
          <v-btn
            color="primary"
            class="mr-2"
            @click="closeWarningDialog">
            Zatvori
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <create-work-order-modal
      :isOpened="isModalOpen"
      :editItemProp="editItem"
      :can-edit="canEdit"
      :editing="editing"
      :mapClass="'excavation-create-location-map-modal'"
      @pull-data="emitPullData"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
import DatetimePicker from '@/global/components/DatetimePicker.vue'
import CreateWorkOrderModal from './CreateWorkOrderModal.vue'
import { formatSqlDateTime, parseDmyDate } from '@/global/services/helpers/dates'
import dayjs from 'dayjs'
import { api } from '@/global/services/api'
import KeyBinder from '@/global/services/helpers/KeyBinder'

const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

export default {
  name: 'ExcavationOrderControls',

  components: {
    DatetimePicker,
    CreateWorkOrderModal
  },

  props: {
    historySwitchVisible: {
      type: Boolean,
      default: false
    },
    resetHistory: {
      type: Boolean,
      default: false
    },
    permissions: {
      type: Array,
      required: true,
      default: () => []
    },
    openEditDialog: {
      type: Boolean,
      default: false
    },
    mapMarkerClicked: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {}
    },
    editItemProp: {
      type: Object,
      default: () => {}
    },
    cityDistricts: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      from: null,
      fromFormatted: null,
      to: null,
      toFormatted: null,
      companyScopeId: null,
      fromMenu: false,
      toMenu: false,
      history: false,
      resetFromTabs: false,
      resetToTabs: false,
      defaultDialogSize: { xl: '55%', lg: '55%', md: '50%', sm: '66.66%', xs: '100%' },
      excavationOrderTypes: {},
      FilterDialog: false,
      selectedExcavationOrderTypes: [],
      isModalOpen: false,
      editItem: {},
      editing: false,
      address: null,
      consentNumber: null,
      menuIsOpen: false,
      year: '',
      selectedCityDistricts: [],
      dateKeys: [
        {
          key: 'requested_at',
          label: 'Datum zahtjeva'
        },
        {
          key: 'consented_at',
          label: 'Datum suglasnosti'
        },
        {
          key: 'work_started_at',
          label: 'Početak rada'
        },
        {
          key: 'work_completed_at',
          label: 'Završetak rada'
        },
        {
          key: 'notification_at',
          label: 'Datum odjave'
        },
        {
          key: 'handovered_at',
          label: 'Datum primopredaje'
        },
        {
          key: 'completed_at',
          label: 'Datum sanacije'
        },
        {
          key: 'billed_at',
          label: 'Datum fakture'
        }
      ],
      dateKey: '',
      warningDialog: false,
      warningMessage: ''
    }
  },

  computed: {
    active () {
      return this.$route.name === 'ExcavationActiveWorkOrders'
    },

    canCreate () {
      const [permissions] = this.permissions
      return this.$route.name === 'ExcavationActiveWorkOrders' && permissions?.createActive === true
    },

    canEdit () {
      const [permissions] = this.permissions
      return permissions?.canEdit === true
    },

    defaultToTime () {
      return '23:59:59'
    },

    minDate () {
      return dayjs(this.from).format('YYYY-MM-DD')
    },

    maxDate () {
      return dayjs(this.to).format('YYYY-MM-DD')
    }
  },

  watch: {
    from (from) {
      this.fromFormatted = formatSqlDateTime(from, 'DD.MM.YYYY HH:mm')
      if (dayjs(this.from).add(1, 'year').isBefore(this.to)) {
        this.to = dayjs(this.from).add(1, 'year')
      }
    },

    to (to) {
      if (dayjs(this.to).subtract(1, 'year').isAfter(this.from)) {
        this.from = dayjs(this.to).subtract(1, 'year')
      }
      this.toFormatted = formatSqlDateTime(to, 'DD.MM.YYYY HH:mm')
    },

    openEditDialog (open) {
      this.isModalOpen = open
      this.editing = open
    },
    editItemProp (item) {
      this.editItem = item
    },
    fromMenu (value) {
      this.$emit('dialog-open-status', value)
      this.resetFromTabs = !this.resetFromTabs
    },

    toMenu (value) {
      this.$emit('dialog-open-status', value)
      this.resetToTabs = !this.resetToTabs
    },

    history (value) {
      this.$emit('show-history', value)
    },
    historySwitchVisible (value) {
      if (!value) {
        this.history = false
      }
    },
    resetHistory (value) {
      if (value) {
        this.history = false
      }
    }
  },

  created () {
    this.getYear()
    this.from = dayjs().subtract(1, 'year').toISOString()
    this.to = dayjs().add(1, 'year').toISOString()
    this.dateKey = this.dateKeys[0]
    this.fetchFilters()
    this.keyCallbacks = {
      f: this.handleKeyDownF,
      F: this.handleKeyDownF,
      Enter: this.handleKeyDownEnter,
      Escape: this.handleKeyDownEscape
    }
    this.keyBinder = new KeyBinder(this.keyCallbacks)
    this.keyBinder.bind()
  },

  destroyed () {
    if (this.keyBinder) {
      this.keyBinder.unbind()
    }
  },

  methods: {
    handleKeyDownF () {
      if (!this.isModalOpen && !this.FilterDialog && !this.mapMarkerClicked) {
        this.FilterDialog = true
      }
    },
    handleKeyDownEscape () {
      if (this.FilterDialog) {
        this.FilterDialog = false
      }
    },
    handleKeyDownEnter () {
      if (!this.isModalOpen && this.FilterDialog && !this.menuIsOpen) {
        if (!this.selectedExcavationOrderTypes.length) {
          this.openWarningDialog(this.$t('road-maintenance/excavation-service.filter_alert'))
        }
        else if (this.year && (!this.year.toString().length || this.year.toString().length !== 4)) {
          this.openWarningDialog('Unesite ispravnu godinu')
        }
        else {
          this.filterStatus()
          this.emitGetOptions()
          this.$emit('show-table')
        }
      }
    },
    closeDatetimePicker () {
      if (this.fromMenu) {
        this.fromMenu = false
      }
      if (this.toMenu) {
        this.toMenu = false
      }
      this.emitGetOptions()
      this.$emit('pull-data')
    },

    emitPullData () {
      this.$emit('pull-data')
    },

    closeFromMenu () {
      this.emitGetOptions()
      this.$emit('pull-data')
      this.fromMenu = false
    },

    closeToMenu () {
      this.emitGetOptions()
      this.$emit('pull-data')
      this.toMenu = false
    },

    closeModal () {
      this.isModalOpen = false
      this.$emit('close-edit-dialog')
    },

    parseDmyDate (date) {
      return parseDmyDate(date)
    },
    async fetchFilters () {
      const params = {
        active: this.active
      }
      const fetched = await api('road-maintenance').get('/excavation-order-types', params)
      this.excavationOrderTypes = Object.keys(fetched.data).map(key => {
        return {
          value: key,
          text: fetched.data[key].title,
          icon: fetched.data[key].icon
        }
      })
      this.selectedExcavationOrderTypes = Object.keys(fetched.data)
      this.emitGetOptions()
    },
    getFiltered () {
      this.emitGetOptions()
      this.$emit('show-table')
    },

    openCreateWorkOrderModal () {
      this.isModalOpen = true
    },

    filterStatus () {
      if (!this.selectedExcavationOrderTypes.length) {
        this.openWarningDialog(this.$t('road-maintenance/excavation-service.filter_alert'))
      }
      else if (this.year && (!this.year.toString().length || this.year.toString().length !== 4)) {
        this.openWarningDialog('Unesite ispravnu godinu')
      }
      else {
        this.emitGetOptions()
        this.$emit('pull-data')
        this.FilterDialog = false
      }
    },
    openFilterDialog () {
      this.FilterDialog = true
    },
    emitGetOptions () {
      this.$emit('get-options', {
        excavationOrderTypes: this.selectedExcavationOrderTypes,
        from: formatSqlDateTime(this.from, 'YYYY-MM-DD'),
        to: formatSqlDateTime(this.to, 'YYYY-MM-DD'),
        consentNumber: this.consentNumber,
        address: this.address,
        year: this.year,
        cityDistricts: Object.values(this.selectedCityDistricts).map(({ id }) => id),
        dateKey: this.dateKey?.key
      })
    },
    async exportData () {
      const params = {
        columns: [
          'consent_number',
          'investor_id',
          'excavation_reason_id',
          'address',
          'request_zc_responsible_person_id',
          'pavement',
          'footpath',
          'home_connection',
          'curb',
          'canals',
          'sidewalk',
          'prp',
          'notification_at',
          'request_investor_responsible_person_id',
          'requested_at',
          'consented_at',
          'work_started_at',
          'work_completed_at',
          'handovered_at',
          'completed_at',
          'billed_at',
          'road_category_id'
        ],
        filters: {
          from: formatSqlDateTime(this.from, 'YYYY-MM-DD'),
          to: formatSqlDateTime(this.to, 'YYYY-MM-DD'),
          status: this.selectedExcavationOrderTypes,
          active: this.active,
          address: this.address,
          consentNumber: this.consentNumber,
          year: this.year,
          cityDistricts: Object.values(this.selectedCityDistricts).map(({ id }) => id),
          dateKey: this.dateKey?.key
        },
        sort: {
          by: this.options.sortBy,
          desc: this.options.sortDesc
        }
      }
      this.importOrExportLoading = true
      const url = new URL(`${window.location.origin}/api/road-maintenance/excavation-order-export`)
      params.columns.forEach(column => url.searchParams.append('columns[]', column))
      url.searchParams.append('filters', JSON.stringify(params.filters))
      url.searchParams.append('sort', JSON.stringify(params.sort))
      let objectURL = null
      fetch(url.toString())
        .then(res => {
          if (!res.ok) {
            throw new Error(res.statusText)
          }
          return res.arrayBuffer()
        })
        .then(arrayBuffer => {
          // BE endpoint sends a readable stream of bytes
          const byteArray = new Uint8Array(arrayBuffer)
          const link = document.createElement('a')
          objectURL = URL.createObjectURL(
            new Blob(
              [byteArray],
              { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
            )
          )
          link.href = objectURL
          const filename = this.active ? this.$t('road-maintenance.excavation-service-active-work-orders') : this.$t('road-maintenance.excavation-service-inactive-work-orders')
          link.download = `${filename}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .finally(() => {
          this.importOrExportLoading = false
          if (objectURL) URL.revokeObjectURL(objectURL)
        })
    },
    async getYear () {
      this.year = await api('road-maintenance').get('/excavation-order/get-filter-year')
    },
    closeWarningDialog () {
      this.warningDialog = false
      this.warningMessage = ''
    },
    openWarningDialog (message) {
      this.warningMessage = message
      this.warningDialog = true
    }
  }
}
</script>
<style scoped>
  h2 {
    font-size: 14px;
    font-weight: normal;
    padding-bottom: 0;
  }
  .circle{
    box-shadow: 0 0 5px 2px;
    border-radius: 50%;
    height:12px;
    width: 12px;
    margin-left: 10px;
  }
  .red_icon {
    background: #ca2a19;
  }

  .yellow_icon {
    background: #ffe545;
  }

  .green_icon {
    background: #519548;
  }

  .orange_icon {
    background: #ff8f40;
  }
</style>
