<template>
  <v-dialog
    v-model="isOpen"
    persistent
    max-width="650"
    @keydown.enter.prevent="closeWorkOrderConfirmed"
  >
    <v-card
    >
      <v-row class="flex justify-space-between ma-0">
        <v-card-title> {{ $t('road-maintenance.excavation_delete_order_modal_title') }} </v-card-title>

        <v-btn
          text
          x-small
          class="mt-3 mr-2 pa-0 no-background-hover"
          elevation="0"
          @click="closeClosingWorkOrderDialog"
        >
          <v-icon
            color="grey darken-1"
            size="1.8rem"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-row>

      <v-card-text> {{ $t('road-maintenance.excavation_delete_order_modal_message') }} </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="red"
          text
          @click="closeWorkOrderConfirmed"
        >
          {{ $t('road-maintenance.excavation_delete_order_modal_confirm_btn') }}
        </v-btn>

        <v-btn
          color="grey darken-1"
          text
          @click="closeClosingWorkOrderDialog"
        >
          {{ $t('road-maintenance.excavation_delete_order_modal_cancel_btn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeletePrpModal',

  props: {
    openModal: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      isOpen: false
    }
  },

  watch: {
    openModal: {
      deep: true,
      handler (val) {
        this.isOpen = val
      }
    }
  },

  methods: {
    closeWorkOrderConfirmed () {
      this.$emit('close-work-order-confirmed', this.id)
    },

    closeClosingWorkOrderDialog () {
      this.$emit('close-work-order-canceled')
    }
  }

}
</script>

<style scoped>

</style>
