<template>
  <map-base
    ref="mapBase"
    :style="mapStyle"
    class="map-prp-base"
    :map-options="mapOptions"
    :map-options-picker-config="mapPickerConfig"
    @map-options-updated="mapOptionsUpdated"
    @marker-zoom-updated="zoomLevel => $emit('set-history-switch-visible', zoomLevel > 14)"
  >
    <template #mapContent>
      <manage-prp-modal
        :is-opened="isModalOpen"
        :edit-item-prop="editItem"
        :editing="editing"
        :map-class="'prp-location-map-modal'"
        @pull-data="emitPullData"
        @close-modal="closeModal"
        @pull-fresh-edit-item="val => openEditDialog(val)"
      />
    </template>
  </map-base>
</template>

<script>
import L from 'leaflet'
import { createNamespacedHelpers } from 'vuex'
import ManagePrpModal from './ManagePrpModal.vue'
import MapBase from '@/global/components/map/MapBase.vue'
import { api } from '@/global/services/api'
import { isEmpty, isObject } from 'lodash'
const { mapActions, mapGetters } = createNamespacedHelpers('road-maintenance/prp')
export default {
  name: 'PrpMap',

  components: {
    ManagePrpModal,
    MapBase
  },

  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    invokeGetIcons: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      markers: {},
      markersClusterGroup: {},
      oldSelectedItem: false,
      isModalOpen: false,
      editItem: {},
      editing: true,
      mapStyle: {
        zIndex: 0,
        flex: 1
      },
      markerConfig: [],
      ready: false,
      mapOptions: {
        renderer: L.canvas()
      },
      mapPickerConfig: {
        content: [
          {
            title: 'Opcije',
            items: [
              {
                id: 'showCityDistricts',
                label: 'Prikaži gradske četvrti'
              }
            ]
          }
        ]
      }
    }
  },

  computed: {
    ...mapGetters(['mapItems', 'icons', 'cityDistricts'])
  },

  watch: {
    invokeGetIcons: {
      immediate: true,
      async handler (val) {
        if (val) {
          await this.generateIcons()
          this.$emit('reset-invoke-get-icons')
        }
      }
    },

    options: {
      immediate: true,
      deep: true,
      async handler (newOptions) {
        if (newOptions && newOptions.from && newOptions.to) {
          this.$refs?.mapBase?.startMapLoader()
          await this.getIcons({
            status: newOptions.status,
            from: newOptions.from,
            to: newOptions.to,
            consentNumber: newOptions.consent_number,
            address: newOptions.address,
            year: newOptions.year
          })

          this.mapItems.forEach(item => {
            item.click = () => {
              this.openEditDialog(item.id)
            }
          })
          const markersConfig = {
            markers: this.mapItems,
            icons: this.icons,
            markerClusterOptions: {
              maxClusterRadius: 40
            }
          }

          this.generateMarkers(markersConfig)
          this.$refs?.mapBase?.stopMapLoader()
        }
      }
    }
  },
  methods: {
    ...mapActions(['getIcons', 'fetchCityDistricts']),

    async generateIcons (fitMarkers = true) {
      await this.getIcons({
        status: this.options.status,
        from: this.options.from,
        to: this.options.to,
        consentNumber: this.options.consent_number,
        address: this.options.address,
        year: this.options.year
      })
      if (this.mapItems.length) {
        this.mapItems.forEach(item => {
          item.click = () => {
            this.openEditDialog(item.id)
          }
        })

        const markersConfig = {
          markers: this.mapItems,
          icons: this.icons,
          markerClusterOptions: {
            maxClusterRadius: 40
          },
          fitMarkers: fitMarkers
        }
        this.generateMarkers(markersConfig)
      }
      else {
        this.$refs.mapBase?.removeMarkers()
      }
    },

    closeModal () {
      this.isModalOpen = false
      this.$emit('close-edit-dialog')
    },

    async openEditDialog (item) {
      const id = isObject(item) && item.id ? item.id : item
      const response = await api('road-maintenance').get('temporary-traffic-regulation/' + id)
      const { data } = response || {}

      if (data && isObject(data) && !isEmpty(data)) {
        this.editItem = {
          id: data?.id,
          address: data?.geolocation?.address,
          latitude: data?.geolocation?.latitude,
          longitude: data?.geolocation?.longitude,
          applicant: data?.applicant,
          consent_number: data?.consent_number,
          start_date: data?.start_date,
          end_date: data?.end_date,
          status: data?.status?.name,
          zc_responsible_person: data?.zc_responsible_person,
          extensions: data?.extensions || [],
          record_pdfs: data?.record_pdfs,
          record_images: data?.record_images
        }
      }
      else {
        this.editItem = {}
      }

      this.$emit('open-edit-dialog-map')
      this.isModalOpen = true
    },

    generateMarkers (markersConfig) {
      this.$nextTick(() => {
        this.$refs?.mapBase?.onMapReady(function (map) {
          map.generateMarkers(markersConfig)
        })
      })
    },

    emitPullData () {
      this.$emit('pull-data')
    },

    // Fetch and show city districts on map
    async mapOptionsUpdated (updatedOptions) {
      if (!this.cityDistricts.length) {
        await this.fetchCityDistricts()
      }

      if (updatedOptions.showCityDistricts) {
        if (this.cityDistricts && this.cityDistricts.length) {
          const polygonConfig = {
            polygons: []
          }
          this.cityDistricts.forEach(cityDistrict => {
            if (cityDistrict && !isEmpty(cityDistrict) && cityDistrict.data) {
              const coordinatesString = cityDistrict.data.match(/\(\(([^)]+)\)\)/)[1]

              const coordinatesPairs = coordinatesString.split(', ')

              const coordinatesArray = coordinatesPairs.map(pair => {
                const [latitude, longitude] = pair.split(' ').map(parseFloat)
                return [latitude, longitude]
              })
              polygonConfig.polygons.push({
                coordinates: coordinatesArray,
                label: cityDistrict.name,
                options: {
                  color: cityDistrict.color
                }
              })
              this.$refs?.mapBase?.generatePolygons(polygonConfig)
            }
          })
        }
      }
      else {
        this.$refs?.mapBase?.removeAllPolygons()
      }
    },

    // Invalidate map size function to be called from parent component so the missing tiles are fetched
    invalidateMapSize () {
      this.$refs?.mapBase?.getMapObject()?.invalidateSize()
    }
  }
}
</script>

<style lang="scss">
.map-prp-base {
  .leaflet-control .v-divider{
    margin: 8px 0;
  }
  .v-icon {
    font-size: 1.5rem;
  }
  .v-label {
    font-size: 0.82rem;
  }

  .red_icon,
  .red_status{
    background: #ca2a19;
  }

  .green_icon,
  .green_status{
    background: #519548;
  }

  .red_icon,
  .green_icon {
    z-index:1000;
    border-radius: 50%;
    box-shadow: 0 0 10px 2px;
  }

  .red_icon:hover,
  .green_icon:hover {
    box-shadow: 0 0 10px 2px orange;
  }
}
</style>
